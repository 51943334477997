:root {

  --grape: lch(40.014% 108.495 309.59);
  --grape-tint: lch(44.014% 106.495 310.59);
  --grape-shade: lch(36.014% 110.495 308.59);
  --grape-gradient: linear-gradient(120deg, var(--grape-tint), var(--grape-shade));
  --raspberry: lch(57.859% 97.892 326.222);
  --raspberry-tint: lch(61.859% 95.892 327.222);
  --raspberry-shade: lch(53.859% 99.892 325.222);
  --raspberry-gradient: linear-gradient(120deg, var(--raspberry-tint), var(--raspberry-shade));
  --peach: lch(71.881% 40.541 32.926);
  --peach-tint: lch(75.881% 38.541 33.926);
  --peach-shade: lch(67.881% 42.541 31.926000000000002);
  --peach-gradient: linear-gradient(120deg, var(--peach-tint), var(--peach-shade));
  --lemon: lch(94.17% 75.545 100.177);
  --lemon-tint: lch(98.17% 73.545 101.177);
  --lemon-shade: lch(90.17% 77.545 99.177);
  --lemon-gradient: linear-gradient(120deg, var(--lemon-tint), var(--lemon-shade));
  --pear: lch(77.247% 88.908 127.145);
  --pear-tint: lch(81.247% 86.908 128.14499999999998);
  --pear-shade: lch(73.247% 90.908 126.145);
  --pear-gradient: linear-gradient(120deg, var(--pear-tint), var(--pear-shade));
  --lychee: lch(94.314% 2.996 284.311);
  --lychee-tint: lch(98.314% 0.996 285.311);
  --lychee-shade: lch(90.314% 4.996 283.311);
  --lychee-gradient: linear-gradient(120deg, var(--lychee-tint), var(--lychee-shade));
  --blackberry: lch(9.116% 7.269 287.344);
  --blackberry-tint: lch(13.116% 5.269 288.344);
  --blackberry-shade: lch(5.116% 9.269 286.344);
  --blackberry-gradient: linear-gradient(120deg, var(--blackberry-tint), var(--blackberry-shade));
  --white: lch(100% 0 0);
  --black: lch(4.807% 3.011 285.248);

  --emerald: #047857;
  --emerald-tint: #34d399;
  --emerald-shade: #10b981;
  --emerald-gradient: linear-gradient(120deg, var(--emerald-tint), var(--emerald-shade));

  --green: #22c55e;
  --green-tint: #22c55e;
  --green-shade: #22c55e;
  --green-gradient: linear-gradient(120deg, var(--green-tint), var(--green-shade));

  --slate: #f8fafc;
  --slate-tint: #f8fafc;
  --slate-shade: #f1f5f9;
  --slate-gradient: linear-gradient(120deg, var(--slate-tint), var(--slate-shade));

  --primary: var(--slate);
  --primary-tint: var(--slate-tint);
  --primary-shade: var(--slate-shade);
  --primary-gradient: linear-gradient(120deg, var(--primary-tint), var(--primary-shade));

  --secondary: var(--lychee);
  --secondary-tint: var(--lychee-tint);
  --secondary-shade: var(--lychee-shade);
  --secondary-gradient: linear-gradient(120deg, var(--secondary-tint), var(--secondary-shade));

  --tertiary: var(--emerald);
  --tertiary-tint: var(--emerald-tint);
  --tertiary-shade: var(--emerald-shade);
  --tertiary-gradient: linear-gradient(120deg, var(--tertiary-tint), var(--tertiary-shade));
  --tertiary-transparent: color-mix(in lch,
      var(--tertiary-tint),
      60% transparent);
  --text-color: var(--black);
   // --secondary: var(--raspberry);
    // --secondary-tint: var(--raspberry-tint);
    // --secondary-shade: var(--raspberry-shade);
    // --secondary-gradient: linear-gradient(120deg, var(--secondary-tint), var(--secondary-shade));
    // --tertiary: var(--lemon);
    // --tertiary-tint: var(--lemon-tint);
    // --tertiary-shade: var(--lemon-shade);
    // --tertiary-gradient: linear-gradient(120deg, var(--tertiary-tint), var(--tertiary-shade));

    --lemonade-gradient: linear-gradient(120deg, var(--lemon), var(--peach) 40%, var(--raspberry));

  --primary-transparent: color-mix(in lch,
      lch(40.014% 108.495 309.59),
      60% transparent);

  --lychee-transparent: color-mix(in lch,
      lch(94.314% 2.996 284.311),
      60% transparent);

}


*,
*::before,
*::after {
  box-sizing: border-box;
}

// html {
//   font-size: 62.5%;
// }

// /* MyComponent.module.css */
// .rootFontSize {
//   :root {
//     font-size: 62.5%;
//   }
// }


.product_card_container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // h3 {
  // line-height: 1.2;
  // }

  // .text-small {
  // font-size: 80%;
  // }

  // fromt html
  // font-size: 62.5%;

  // from :root
  // --primary-transparent: color-mix(in lch,
  //     var(--grape-tint),
  //     60% transparent);
  // --lychee-transparent: color-mix(in lch,
  //     lch(94.314% 2.996 284.311),
  //     60% transparent);
  // --blackberry: lch(9.116% 7.269 287.344);

  padding: 0;
  width: 100%;
  // min-height: 100vh;

  // background: var(--lychee);

  // font: 400 normal 1.2rem/1.6 system-ui, sans-serif;
  // color: var(--blackberry);


}


// h3 {
//   line-height: 1.2;
// }

.text-small {
  font-size: 80%;
}



.product_card {

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 22rem;

  border-radius: 12px;
  background-color: var(--primary);
  box-shadow: 0 .5rem 2rem 0 color-mix(in lch,
      var(--primary-shade),
      95% transparent);

  overflow: hidden;

  font: 400 normal 1.2rem/1.6 system-ui, sans-serif;
  color: var(--text-color);

  &:hover {
    .product_card--image {
      height: 130px;
    }
  }

  &::after {

    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    padding: 2rem 0.5rem 1.2rem 0.5rem;
    width: 100%;
    height: 21.5rem;

    border-radius: 12px;
    background-color: var(--primary);
    background-image: var(--primary-gradient);

    transition: all 350ms ease-in-out;

  }

  &:hover::after,
  &:focus-within::after {
    height: 100%;
    padding: 2rem 0.5rem 1.2rem 0.5rem;

  }



  &--header {

    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // padding: 3.6rem 3.6rem 2.4rem 3.6rem;
    padding: 1.2rem 0.5rem 1.2rem 0.5rem;
    width: 100%;
    height: 20rem;

    color: var(--text-color);

    transition: all 350ms ease-in-out;



    h3 {
      margin: 1.2rem 0 0 0;
      padding: 0;
    }

    &--description {
      align-items: left;
    }

    &--author {
      align-items: left;
    }

  }

  &--image {

    width: 280px;
    height: 180px;

    // display: flex;
    // justify-content: center;
    // align-items: center;

    border-radius: 8px;
    background-color: var(--primary);
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &--text {
    padding: 0% 3%;
    text-align: left;
  }

  &--description {
    font-size: 0.9rem;
    text-align: left;
    margin-top: 0.1rem;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &--author {
    margin-top: 0.2rem;
    padding-left: 0.2rem;
    text-align: left;
    font-size: 0.8rem;
    // display: none;
  }

  &:hover .product_card--header,
  &:focus-within .product_card--header {
    transform: translateY(-2rem);

    &--image {
      height: 150px;
      transition: height 0.3s ease-in-out;
    }

    &--description {
      -webkit-line-clamp: 2;
      transition: all 0.3s ease-in-out;
    }

  }



  &--info {

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    // padding: 2.4rem 3.6rem;
    padding: 2rem 2rem;
    width: 100%;

    color: var(--text-color);
    line-height: 1;

    transition: all 350ms ease-in-out;


    p {
      margin: 0;
      padding: 0;
    }

  }


  &:hover .product_card--info,
  &:focus-within .product_card--info {
    color: var(--text-color);
    transform: translateY(-4.8rem);
  }


  &--meta {

    display: flex;
    align-items: center;
    justify-content: space-between;

  }


  &--difficulty {

    position: relative;

    margin-right: .9rem;
    width: 1.2rem;
    height: .4rem;

    border-radius: 1rem;
    background-color: var(--primary);

    transition: all 350ms ease-in-out;


    &::before,
    &::after {

      content: '';

      position: absolute;
      left: 0;

      width: 1.2rem;
      height: .4rem;

      border-radius: 1rem;
      background-color: var(--tertiary);

      transition: all 350ms ease-in-out;

    }


    &::before {
      top: -.7rem;
    }

    &::after {
      top: .7rem;
    }


    &--one {
      background-color: var(--tertiary-transparent);
    }

    &--one::before {
      background-color: var(--tertiary-transparent);
    }

    &--two::before {
      background-color: var(--tertiary-transparent);
    }

  }


  &:hover,
  &:focus-within {

    .product_card--difficulty,
    .product_card--difficulty::before,
    .product_card--difficulty::after {
      background-color: var(--tertiary);
    }

    .product_card--difficulty--one {
      background-color: var(--tertiary-transparent);
    }

    .product_card--difficulty--one::before {
      background-color: var(--tertiary-transparent);
    }

    .product_card--difficulty--two::before {
      background-color: var(--tertiary-transparent);
    }

  }


  &--topic {

    padding: .6rem 1.2rem;

    border-radius: 5rem;
    background-color: var(--tertiary-shade);
    // background-image: linear-gradient(180deg, var(--tertiary-shade) 0%, var(--tertiary-shade) 50%, var(--lychee-transparent) 50%, var(--lychee-transparent) 100%);
    background-size: 100% 200%;
    background-position: top;

    color: var(--white);

    transition: all 250ms ease-in-out;

  }


  &:hover .product_card--topic,
  &:focus-within .product_card--topic {
    background-position: bottom;
  }


  &--cta {

    z-index: 2;

    position: absolute;
    bottom: 0;
    left: 0;

    padding: 0 3.6rem;
    width: 100%;

    opacity: 0;

    transition: all 350ms ease-in-out;


    .btn {

      padding: .4rem .6rem;
      width: 100%;

      outline: none;
      border-radius: 5rem;
      border: 2px solid transparent;
      background-color: var(--tertiary-shade);

      font-size: 0.9em;
      color: var(--white);

      transition: all 350ms ease-in-out;


      &:hover,
      &:focus {

        background-color: var(--tertiary-shade);

        color: var(--text-color);

      }
    }
  }


  &:hover .product_card--cta,
  &:focus-within .product_card--cta {

    transform: translateY(-2.4rem);

    opacity: 1;

  }

  // DOESN'T WORK
  // &--popup {
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 200px;
  //   background-color: var(--primary);
  //   border-radius: 8px;
  //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  //   padding: 16px;
  //   opacity: 0;
  //   visibility: hidden;
  //   transition: opacity 0.3s, visibility 0.3s;
  //   z-index: 3;

  //   &-content {
  //     text-align: center;

  //     h4 {
  //       margin-top: 0;
  //       margin-bottom: 8px;
  //     }

  //     p {
  //       margin: 0;
  //     }
  //   }
  // }

  // &:hover,
  // &:focus-within {
  //   .product_card--popup {
  //     opacity: 1;
  //     visibility: visible;
  //   }
  // }
}