/* flashcard.css */
.flashcard {
  width: 800px;
  height: 420px;
  background-color: #ffffff;
  padding: 30px 60px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-width: 1px;
  text-align: center;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  perspective: 1000px;
  /* For 3D effect */
}


.question,
.answer {
  width: 100%;
  height: 100%;
  // position: absolute;
  // backface-visibility: hidden;
  /* Hide the back when not flipped */
  // transition: transform 0.5s;
  /* Smooth flip transition */
}

.question {
  font-size: 48px;
  // font-weight: bold;
  margin-bottom: 10px;

  // background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer {
  font-size: 48px;
  // color: #555;
  margin-bottom: 10px;

  // background-color: #e0e0e0;
  // transform: rotateX(180deg);
  /* Initially flipped */
  display: flex;
  align-items: center;
  justify-content: center;
}

// .flashcard:hover .question {
//   transform: rotateX(180deg); /* Flip on hover */
// }

// .flashcard:hover .answer {
//   transform: rotateY(0); /* Flip on hover */
// }

.timer {
  font-size: 24px;
  margin-bottom: 20px;
}

.toggle-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #dc3545;
}

.reset-button {
  background-color: #ffc107;
  color: #333;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.toggle-answer-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

/* Add more styles as needed */