.code-block-container {
  background-color: #282c34;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #21252b;
  color: #fff;
  font-family: monospace;
}

.copy-button {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.copy-button:hover {
  color: #c678dd;
}