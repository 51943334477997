$primary: #20B486;
$accent: #6ee7b7;
$bg: #ffffff;
$bg-accent: #e7e8e9;
$disabled: #9c9c9c;
$foreground: #2d264b;


// className="bg-gradient-to-r from-[#6ee7b7] to-[#20B486] py-3 px-4 mx-3 rounded-md text-[#ECFDF5]"


// body {
//   font-family: monospace;
//   background: linear-gradient(0deg, $primary 0.03%, $accent);
//   color: $foreground;
//   display: flex;
//   justify-content: center;
//   margin: 0 auto;
//   padding: 0 30px;
//   height: 100vh;
// }

.quiz-container {
  width: 700px;
  background: $bg;
  border-radius: 4px;
  border-width: 1px;
  margin-top: 100px;
  padding: 30px 60px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  .active-question-no {
    font-size: 32px;
    font-weight: 500;
    color: $primary;
  }

  .total-question {
    font-size: 16px;
    font-weight: 500;
    color: $disabled;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  ul {
    margin-top: 20px;
    margin-left: 0px;

    li {
      text-decoration: none;
      list-style: none;
      color: $foreground;
      font-size: 16px;
      background: $bg;
      border: 1px solid $disabled;
      border-radius: 16px;
      padding: 11px;
      margin-top: 15px;
      cursor: pointer;
    }

    .selected-answer {
      background: $primary;
      border: 1px solid $accent;
      color: $bg;
    }
  }

  .footer-quiz {
    display: flex;
    justify-content: flex-end;
  }

  button {
    background: linear-gradient(0deg, $primary 0.03%, $accent);
    border-radius: 9px;
    font-size: 18px;
    color: $bg;
    padding: 10px 42px;
    outline: none;
    border: none;
    margin-top: 15px;
    cursor: pointer;

    &:disabled {
      background: $bg-accent;
      color: $disabled;
      cursor: not-allowed;
    }
  }

  .result-quiz {
    text-align: center;

    h3 {
      font-size: 24px;
      letter-spacing: 1.4px;
      text-align: center;
    }

    p {
      font-size: 16px;
      font-weight: 500;

      span {
        color: $primary;
        font-size: 22px;
      }
    }
  }
}