@import url(../node_modules/semantic-ui-css/semantic.min.css);
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

option {
    color: black;
}


.icon {
    outline-style: none;
}

.tab {
    display: inline-block;
    margin-left: 4em;
}

.space {
    display: inline-block;
    margin-left: 0.5em;
}

.container-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}